// src/Components/ChatWindow.js

import React, {
  useEffect,
  useCallback,
  useRef,
  useMemo,
  useState,
} from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import webSocketManager from '../services/WebSocketManager'; // Adjust if needed

// UPDATED: Import addOptimisticMessage for immediate local insertion
import {
  initiateOutgoingCall,
  receiveCallNotification,
  endCall,
  setSelectedUser,
  clearErrors,
  addOptimisticMessage,
} from '../slices/notificationSlice';

import styled, { keyframes } from 'styled-components';
import MessageInput from './MessageInput';
import { FiPhone, FiVideo, FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

/* ------------------------------------------------------------------
   Styled Components
------------------------------------------------------------------ */

const ChatWindowContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  /* Force child elements to fill the container properly */
  height: 100%;
  overflow: hidden; /* Let internal elements (like react-window) handle scrolling */
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  border-bottom: 1px solid #ddd;

  @media (max-width: 768px) {
    padding: 10px 15px;
    flex-direction: row;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled.h2`
  font-weight: 600;
  font-size: 1.2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const BackButton = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 10px;

  @media (max-width: 768px) {
    display: block;
  }
`;

const DateSeparator = styled.div`
  text-align: center;
  margin: 10px 0;
  color: #888;
  font-size: 14px;
`;

const CallButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const IconButton = styled.button`
  background: ${(props) => (props.$isEnd ? '#e74c3c' : '#3498db')};
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;

  &:hover {
    background: ${(props) => (props.$isEnd ? '#c0392b' : '#2980b9')};
  }

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 1rem;
  }
`;

const MessagesContainer = styled.main`
  flex: 1;
  /* Add a flex layout so the react-window can fill the space */
  display: flex;
  flex-direction: column;
  overflow: hidden;

  padding: 15px;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.$isCurrentUser ? 'flex-end' : 'flex-start'};
  margin-bottom: 10px;
`;

const Loader = styled.div`
  text-align: center;
  padding: 10px;
  color: #888;
`;

const EndOfMessages = styled.div`
  text-align: center;
  padding: 10px;
  color: #888;
`;

const MessageBubble = styled.div`
  background-color: ${(props) =>
    props.$isCurrentUser ? '#DCF8C6' : '#E8E8E8'};
  border-radius: 10px;
  padding: 10px 15px;
  max-width: 70%;
  word-wrap: break-word;
  font-size: 1rem;

  @media (max-width: 768px) {
    max-width: 80%;
    padding: 8px 12px;
    font-size: 0.9rem;
  }
`;

const MessageInputContainer = styled.footer`
  border-top: 1px solid #ddd;
  padding: 10px;

  @media (max-width: 768px) {
    padding: 8px;
  }
`;

const Card = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: ${(props) =>
    props.$isCurrentUser
      ? '2px 2px 5px rgba(0, 128, 0, 0.2)'
      : '2px 2px 5px rgba(0, 0, 0, 0.1)'};
  padding: 10px 15px;
  max-width: 70%;
  word-wrap: break-word;
  font-size: 1rem;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    max-width: 80%;
    padding: 8px 12px;
    font-size: 0.9rem;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  width: 24px;
  height: 24px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-top-color: #000;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

/* ------------------------------------------------------------------
   Main ChatWindow Component
------------------------------------------------------------------ */
const ChatWindow = React.memo(({ selectedUser, roomName, currentUser, onBack }) => {
  const dispatch = useDispatch();

  // Local State
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  // Redux State
  const {
    isInCall,
    outgoingCall,
    currentCall,
  } = useSelector(
    (state) => ({
      isInCall: state.notifications.isInCall,
      outgoingCall: state.notifications.outgoingCall,
      currentCall: state.notifications.currentCall,
    }),
    shallowEqual
  );

  const messages = useSelector(
    (state) => state.notifications.chatMessages?.[roomName] || [],
    shallowEqual
  );

  // For error messages that match this room
  const errorMessages = useSelector(
    (state) => state.notifications.errors.filter((err) => err.roomName === roomName),
    shallowEqual
  );

  // Refs
  const dialerRef = useRef(null);
  const listRef = useRef(null);

  // For infinite scrolling
  const ITEM_SIZE = 80;
  const previousMessagesLengthRef = useRef(0);
  const isInitialLoadRef = useRef(true);
  const scrollHeightBeforeRef = useRef(0);
  const scrollTopBeforeRef = useRef(0);

  // Helpers
  const getCurrentUserId = useCallback(() => {
    // Adjust logic to your user shape
    if (currentUser?.user?.id) return currentUser.user.id;
    if (currentUser?.patient?.user?.id) return currentUser.patient.user.id;
    return null;
  }, [currentUser]);

  const isInActiveCall = useCallback(() => {
    return isInCall && (currentCall || outgoingCall);
  }, [isInCall, currentCall, outgoingCall]);

  // Full Name helper
  const getCurrentUserFullName = useCallback(() => {
    if (currentUser?.user) {
      const { first_name = 'Me', last_name = '' } = currentUser.user;
      return `${first_name} ${last_name}`.trim();
    } else if (currentUser?.patient?.user) {
      const { first_name = 'Me', last_name = '' } = currentUser.patient.user;
      return `${first_name} ${last_name}`.trim();
    }
    return 'Me';
  }, [currentUser]);

  // Call Controls
  const startCall = useCallback(
    (type) => {
      if (isInActiveCall()) {
        console.log("Already in an active call, skipping startCall()");
        return;
      }
      if (!selectedUser?.user?.id) {
        alert("No user selected for the call.");
        return;
      }

      const toUserId = selectedUser.user.id;

      if (!isInCall) {
        dispatch(
          setSelectedUser({
            user: {
              id: toUserId,
              first_name: selectedUser.user.first_name,
              last_name: selectedUser.user.last_name,
            },
          })
        );
      }

      // Play dialer tune only once
      if (dialerRef.current && !isInCall) {
        dialerRef.current.play().catch((err) => {
          console.error("Dialer play error:", err);
        });
      }

      if (!isInCall) {
        dispatch(
          initiateOutgoingCall({
            toUserId,
            callType: type,
            roomName,
          })
        );
        // Notify server
        webSocketManager.initiateCall(toUserId, type);
      }
    },
    [isInActiveCall, dispatch, isInCall, selectedUser, roomName]
  );

  const handleEndCall = useCallback(() => {
    if (currentCall) {
      webSocketManager.endCall(currentCall.fromId, roomName);
    } else if (outgoingCall) {
      webSocketManager.endCall(outgoingCall.toUserId, roomName);
    }
    dispatch(endCall({ roomName }));
    dispatch(setSelectedUser(null));

    // Stop dialer
    if (dialerRef.current) {
      dialerRef.current.pause();
      dialerRef.current.currentTime = 0;
    }
  }, [currentCall, outgoingCall, roomName, dispatch]);

  useEffect(() => {
    if (isInCall && (currentCall || outgoingCall)) {
      if (dialerRef.current) {
        dialerRef.current.pause();
        dialerRef.current.currentTime = 0;
      }
    }
  }, [isInCall, currentCall, outgoingCall]);

  // Chat: fetch + pagination
// 2. Add a timeout to reset loading state if no response is received:
const fetchInitialMessages = useCallback(() => {
  setIsLoading(true);
  webSocketManager.fetchPreviousMessages(roomName, 10);
  
  // Add safety timeout to reset loading state
  const timeoutId = setTimeout(() => {
    setIsLoading(false);
  }, 10000); // 5 second timeout
  
  return () => clearTimeout(timeoutId);
}, [roomName]);

useEffect(() => {
  const cleanup = fetchInitialMessages();
  return () => cleanup();
}, [fetchInitialMessages]);

  const loadMoreMessages = useCallback(() => {
    if (isLoading || !hasMore) return;
    setIsLoading(true);

    if (listRef.current) {
      const list = listRef.current;
      scrollHeightBeforeRef.current = list._outerRef.scrollHeight;
      scrollTopBeforeRef.current = list._outerRef.scrollTop;
    }
    webSocketManager.fetchPreviousMessages(roomName, 10);
  }, [isLoading, hasMore, roomName]);

  // Scrolling
  const scrollToBottom = useCallback(() => {
    if (listRef.current) {
      listRef.current.scrollToItem(messages.length - 1, "end");
    }
  }, [messages.length]);

  const isAtBottom = useCallback(() => {
    if (!listRef.current) return false;
    const list = listRef.current;
    const scrollOffset = list.scrollOffset || 0;
    const { height } = list.props;
    const totalHeight = messages.length * ITEM_SIZE;
    return scrollOffset + height >= totalHeight - ITEM_SIZE;
  }, [messages.length]);

  // WebSocket Handling
  const handleMessage = useCallback(
    (data) => {
      if (data.roomName !== roomName) return;
  
      if (data.fromId === getCurrentUserId()) {
        return;
      }
  
      switch (data.type) {
        case "chat_message": {
          if (isAtBottom() && !isLoading) {
            scrollToBottom();
          }
          break;
        }
  
        case "previous_messages": {
          setIsLoading(false);
          // Handle empty message list case
          if (!data.messages || data.messages.length === 0) {
            setHasMore(false);
          }
          if (data.hasMore === false) {
            setHasMore(false);
          }
          break;
        }
  
        case "call": {
          if (data.fromId !== getCurrentUserId()) {
            dispatch(receiveCallNotification(data));
          }
          break;
        }
  
        case "error": {
          setIsLoading(false);
          break;
        }
  
        default:
          // Always reset loading state for unhandled message types
          setIsLoading(false);
          break;
      }
    },
    [roomName, getCurrentUserId, isLoading, isAtBottom, scrollToBottom, dispatch]
  );
  

  useEffect(() => {
    webSocketManager.onMessage(handleMessage);
    return () => {
      webSocketManager.offMessage(handleMessage);
    };
  }, [handleMessage]);

  useEffect(() => {
    const diff = messages.length - previousMessagesLengthRef.current;
    if (isInitialLoadRef.current && messages.length > 0) {
      scrollToBottom();
      isInitialLoadRef.current = false;
      setIsLoading(false);
    } else if (isLoading && diff > 0 && listRef.current) {
      const list = listRef.current;
      const after = list._outerRef.scrollHeight;
      const before = scrollHeightBeforeRef.current || 0;
      const topBefore = scrollTopBeforeRef.current || 0;

      const delta = after - before;
      list._outerRef.scrollTop = topBefore + delta;

      scrollHeightBeforeRef.current = 0;
      scrollTopBeforeRef.current = 0;
      setIsLoading(false);
    }
    previousMessagesLengthRef.current = messages.length;
  }, [messages, isLoading, scrollToBottom]);

  // Error Handling
  const handleError = useCallback(
    (msg) => {
      if (msg.toLowerCase().includes("call")) {
        alert(msg);
        dispatch(endCall({ roomName }));
      } else {
        console.error("WS Error:", msg);
        alert(`Error: ${msg}`);
      }
    },
    [dispatch, roomName]
  );

  useEffect(() => {
    if (errorMessages.length > 0) {
      errorMessages.forEach((err) => {
        handleError(err.message);
      });
      dispatch(clearErrors());
    }
  }, [errorMessages, handleError, dispatch]);

  // Send Chat Message (Optimistic)
  const sendMessageHandler = useCallback(
    ({ message, files }) => {
      if (!message && (!files || files.length === 0)) return;

      // 1) Add local/optimistic message
      dispatch(
        addOptimisticMessage(
          roomName,
          getCurrentUserId(),
          getCurrentUserFullName(),
          message,
          files
        )
      );

      // 2) Send to server
      webSocketManager.sendMessage(selectedUser.user.id, message, files);
    },
    [dispatch, roomName, selectedUser, getCurrentUserId, getCurrentUserFullName]
  );

  // Prepare data for virtual list
  const processedMessages = useMemo(() => {
    const items = [];
    let lastDate = null;

    messages.forEach((msg) => {
      const msgDate = new Date(msg.timestamp).toDateString();
      if (msgDate !== lastDate) {
        items.push({ type: "date", date: msgDate });
        lastDate = msgDate;
      }
      items.push({ type: "message", message: msg });
    });
    return items;
  }, [messages]);

  // Virtualized row rendering
  const Row = useCallback(({ index, style, data }) => {
    const item = data[index];
    if (item.type === "date") {
      return (
        <div style={style} key={`date-${item.date}`}>
          <DateSeparator>{item.date}</DateSeparator>
        </div>
      );
    } else if (item.type === "message") {
      const msg = item.message;
      return (
        <div style={style} key={msg.id}>
          <MessageWrapper $isCurrentUser={msg.isCurrentUser}>
            <Card $isCurrentUser={msg.isCurrentUser}>
              {msg.files?.length > 0 && (
                <div>
                  <strong>Files:</strong>
                  <ul>
                    {msg.files.map((f) => (
                      <li key={f.id || f.url}>
                        <a href={f.url} target="_blank" rel="noreferrer">
                          {f.name || "Download"}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div>{msg.content}</div>
              <div>
                <small>
                  {new Date(msg.timestamp).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </small>
              </div>
            </Card>
          </MessageWrapper>
        </div>
      );
    }
    return null;
  }, []);

  return (
    <ChatWindowContainer>
      <Header>
        <UserInfo>
          {onBack && (
            <BackButton onClick={onBack} aria-label="Back to Previous View">
              <FiArrowLeft />
            </BackButton>
          )}
          <UserName>
            {selectedUser?.user
              ? `${selectedUser.user.first_name} ${selectedUser.user.last_name}`
              : "Unknown User"}
          </UserName>
        </UserInfo>

        <CallButtons>
          {/* Show “start call” buttons if not already in a call */}
          {!isInCall && (
            <>
              <IconButton onClick={() => startCall("audio")} aria-label="Audio Call">
                <FiPhone />
              </IconButton>
              <IconButton onClick={() => startCall("video")} aria-label="Video Call">
                <FiVideo />
              </IconButton>
            </>
          )}
        </CallButtons>
      </Header>

      <MessagesContainer>
  {messages.length === 0 && !isLoading ? (
    <div className="flex items-center justify-center h-full text-gray-500">
      No messages yet
    </div>
  ) : (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height}
          width={width}
          ref={listRef}
          itemCount={processedMessages.length}
          itemSize={ITEM_SIZE}
          itemData={processedMessages}
          overscanCount={5}
          onScroll={({ scrollDirection, scrollOffset }) => {
            if (
              scrollDirection === "backward" &&
              scrollOffset === 0 &&
              hasMore &&
              !isLoading
            ) {
              loadMoreMessages();
            }
          }}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  )}

  {isLoading && (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  )}
</MessagesContainer>

      <MessageInputContainer>
        <MessageInput onSendMessage={sendMessageHandler} />
      </MessageInputContainer>

      {/* Dialer audio (auto-play on call) */}
      <audio ref={dialerRef} src="/static/audio/dialer.mp3" preload="auto" />
    </ChatWindowContainer>
  );
});

export default ChatWindow;
