import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { endCall, clearSignalingMessages } from "../slices/notificationSlice";
import webSocketManager from "../services/WebSocketManager";
import styled from "styled-components";
import {
  FiCamera,
  FiCameraOff,
  FiMic,
  FiMicOff,
  FiPhoneOff,
} from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";

// ----------------- Styled Components -----------------
const VideoCallContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  overflow: hidden;
  z-index: 9999;
`;

const RemoteVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #111;
`;

// Local preview
const LocalVideoPreview = styled.video`
  position: absolute;
  width: 200px;
  height: 150px;
  bottom: 20px;
  right: 20px;
  background: #000;
  object-fit: cover;
  border: 2px solid #fff;
  border-radius: 6px;
  z-index: 10;
`;

const ControlsBar = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  height: 60px;
  background: rgba(0, 0, 0, 0.5);
  padding: 0 16px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  z-index: 11;
`;

const CircleButton = styled.button`
  width: 48px;
  height: 48px;
  background: #333;
  border: none;
  border-radius: 50%;
  color: #fff;
  margin: 0 8px;
  font-size: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #444;
  }
  &:active {
    background: #555;
  }
`;

const RemoteUserName = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  z-index: 11;
`;

// ----------------- VideoCall Component -----------------
const VideoCall = ({
  selectedUser,    // { user: { id, first_name, ... } }
  currentUser,     // local user data
  roomName,
  callType,        // "audio" or "video"
  isInitiator,     // true if we're the caller
  handleEndCall,   // callback to finalize endCall in redux, etc.
}) => {
  console.log(
    "VideoCall: Props =>",
    { selectedUser, currentUser, roomName, callType, isInitiator },
    "mounting of VideoCall"
  );

  const dispatch = useDispatch();

  // ----- Refs -----
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const peerConnectionRef = useRef(null);
  const localStreamRef = useRef(null);
  const remoteStreamRef = useRef(null);

  // Perfect Negotiation: If we’re the *initiator*, we are “impolite.”
  const isPolite = !isInitiator;
  const makingOfferRef = useRef(false);

  // ICE checking/stuck detection
  const iceCheckingTimeoutRef = useRef(null);

  // ----- State -----
  const [isLocalMediaStarted, setIsLocalMediaStarted] = useState(false);
  const [isCameraOn, setIsCameraOn] = useState(callType === "video");
  const [isMicOn, setIsMicOn] = useState(true);

  // Redux: incoming signaling messages
  const signalingMessages = useSelector(
    (state) => state.notifications.signalingMessages[roomName] || []
  );
  const processedMessagesRef = useRef(new Set());

  // ----------------- Utility to getUserMedia with fallback -----------------
  const getUserMediaWithFallback = async (constraints) => {
    try {
      return await navigator.mediaDevices.getUserMedia(constraints);
    } catch (err) {
      // If iOS can't fulfill resolution or user has blocked something
      if (err.name === "OverconstrainedError") {
        console.warn("OverconstrainedError => retry with simpler constraints");
        const fallback = {
          audio: constraints.audio || true,
          video: constraints.video ? true : false, // just boolean if we only need basic
        };
        return await navigator.mediaDevices.getUserMedia(fallback);
      }
      throw err;
    }
  };

  // ----------------- Cleanup -----------------
  const endCallAndCleanup = useCallback(() => {
    console.log("VideoCall: Ending call and cleaning up");

    if (iceCheckingTimeoutRef.current) {
      clearTimeout(iceCheckingTimeoutRef.current);
      iceCheckingTimeoutRef.current = null;
    }

    // Stop local video
    if (localVideoRef.current) {
      localVideoRef.current.srcObject = null;
    }
    // Stop remote video
    if (remoteVideoRef.current) {
      remoteVideoRef.current.srcObject = null;
    }

    // Stop local tracks
    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach((track) => track.stop());
      localStreamRef.current = null;
    }
    remoteStreamRef.current = null;

    // Close peer connection
    if (peerConnectionRef.current) {
      try {
        peerConnectionRef.current.getTransceivers().forEach((transceiver) => {
          try {
            transceiver.stop();
          } catch (err) {
            console.warn("VideoCall: transceiver.stop() error:", err);
          }
        });
      } catch (err) {
        console.warn("VideoCall: error stopping transceivers:", err);
      }
      peerConnectionRef.current.close();
      peerConnectionRef.current = null;
    }

    makingOfferRef.current = false;
    setIsCameraOn(false);
    setIsMicOn(false);
    setIsLocalMediaStarted(false);

    // Dispatch end call in Redux
    dispatch(endCall({ roomName }));
    dispatch(clearSignalingMessages({ roomName }));

    // Run any external cleanup
    if (handleEndCall) {
      handleEndCall();
    }
  }, [dispatch, roomName, handleEndCall]);

  // ----------------- Start Local Media -----------------
  const startLocalMedia = useCallback(async () => {
    if (isLocalMediaStarted) return;

    console.log("VideoCall: Starting local media");
    setIsLocalMediaStarted(true);

    try {
      const constraints = {
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
        video: callType === "video" ? { facingMode: "user" } : false,
      };

      const stream = await getUserMediaWithFallback(constraints);
      localStreamRef.current = stream;

      // Show local preview if we have video
      if (callType === "video" && localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
        localVideoRef.current.muted = true;
        try {
          await localVideoRef.current.play();
          console.log("VideoCall: Local video playing");
        } catch (err) {
          console.warn("VideoCall: localVideoRef autoplay failed:", err);
        }
      }

      // Add tracks to PeerConnection
      const pc = peerConnectionRef.current;
      if (!pc) return;

      stream.getTracks().forEach((track) => {
        pc.addTrack(track, stream);
        console.log("VideoCall: Added local track:", track.kind, track.id);
      });
    } catch (err) {
      console.error("VideoCall: Error starting local media:", err);
      // If user truly denied or something else, end call or handle gracefully
      endCallAndCleanup();
    }
  }, [isLocalMediaStarted, callType, endCallAndCleanup]);

  // ----------------- ICE Candidate Handling (Single) -----------------
  const handleICECandidateEvent = useCallback(
    (event) => {
      const candidate = event.candidate;
      if (candidate) {
        console.log("VideoCall: ICE candidate gathered:", candidate);
        webSocketManager.sendSignalingMessage(selectedUser.user.id, "ice-candidate", {
          roomName,
          candidate,
        });
      } else {
        console.log("VideoCall: ICE gathering complete => sending null candidate");
        webSocketManager.sendSignalingMessage(selectedUser.user.id, "ice-candidate", {
          roomName,
          candidate: null,
        });
      }
    },
    [roomName, selectedUser.user.id]
  );

  // ----------------- Remote Track -----------------
  const handleRemoteTrack = useCallback((event) => {
    console.log("VideoCall: Remote track event:", event.track);

    if (!remoteStreamRef.current) {
      remoteStreamRef.current = new MediaStream();
    }

    // Replace any existing track of the same kind
    const existingTracks = remoteStreamRef.current
      .getTracks()
      .filter((t) => t.kind === event.track.kind);
    existingTracks.forEach((t) => remoteStreamRef.current.removeTrack(t));

    remoteStreamRef.current.addTrack(event.track);

    // Attach to the remote video element
    if (remoteVideoRef.current) {
      remoteVideoRef.current.srcObject = remoteStreamRef.current;
      remoteVideoRef.current
        .play()
        .then(() => {
          console.log("VideoCall: Remote video playing for track:", event.track.id);
        })
        .catch((err) => {
          console.warn("VideoCall: Remote autoplay failed:", err);
          // iOS Safari might require a user gesture
          document.addEventListener(
            "click",
            () => {
              remoteVideoRef.current?.play().catch(() => {
                console.error("VideoCall: Still can't autoplay remote track.");
              });
            },
            { once: true }
          );
        });
    }
  }, []);

  // ----------------- Connection State Changes -----------------
  const handleConnectionStateChange = useCallback(() => {
    const pc = peerConnectionRef.current;
    if (!pc) return;
    const state = pc.connectionState;
    console.log("VideoCall: Connection state changed =>", state);

    if (state === "connected") {
      console.log("VideoCall: Peer connected!");
      if (iceCheckingTimeoutRef.current) {
        clearTimeout(iceCheckingTimeoutRef.current);
        iceCheckingTimeoutRef.current = null;
      }
    } else if (["failed", "disconnected", "closed"].includes(state)) {
      console.warn("VideoCall: PC ended/failed => cleanup");
      endCallAndCleanup();
    }
  }, [endCallAndCleanup]);

  const handleICEConnectionStateChange = useCallback(() => {
    const pc = peerConnectionRef.current;
    if (!pc) return;
    const state = pc.iceConnectionState;
    console.log("VideoCall: ICE connection state =>", state);

    if (state === "checking") {
      if (iceCheckingTimeoutRef.current) {
        clearTimeout(iceCheckingTimeoutRef.current);
      }
      // Example: 10-second timeout for ICE
      iceCheckingTimeoutRef.current = setTimeout(() => {
        console.warn("VideoCall: ICE stuck in checking => attempt ICE restart...");
        if (pc.connectionState !== "connected") {
          if (isInitiator) {
            pc.restartIce();
          } else {
            // As callee, do an ICE restart or re-offer
            if (pc.signalingState === "stable") {
              pc.createOffer({ iceRestart: true })
                .then((offer) => pc.setLocalDescription(offer))
                .then(() => {
                  webSocketManager.sendSignalingMessage(
                    selectedUser.user.id,
                    "offer",
                    {
                      roomName,
                      offer: pc.localDescription,
                    }
                  );
                })
                .catch((err) =>
                  console.error("VideoCall: re-offer ICE restart error:", err)
                );
            }
          }
        }
      }, 10000);
    }

    if (["failed", "disconnected", "closed"].includes(state)) {
      console.warn("VideoCall: ICE disconnected/failed => cleanup");
      endCallAndCleanup();
    }
  }, [isInitiator, endCallAndCleanup, roomName, selectedUser.user.id]);

  // ----------------- onnegotiationneeded -----------------
  const onNegotiationNeeded = useCallback(async () => {
    const pc = peerConnectionRef.current;
    if (!pc) return;

    // Only the initiator (impolite) creates the initial offer
    if (!isInitiator) {
      console.log("VideoCall: onnegotiationneeded => not initiator => ignore");
      return;
    }

    try {
      makingOfferRef.current = true;
      console.log("VideoCall: onnegotiationneeded => creating offer");
      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);

      webSocketManager.sendSignalingMessage(selectedUser.user.id, "offer", {
        roomName,
        offer: pc.localDescription,
      });
    } catch (err) {
      console.error("VideoCall: onnegotiationneeded error:", err);
    } finally {
      makingOfferRef.current = false;
    }
  }, [roomName, selectedUser.user.id, isInitiator]);

  // ----------------- Create PeerConnection on Mount -----------------
  useEffect(() => {
    if (peerConnectionRef.current) {
      console.log("VideoCall: PC already exists => skip creation");
      return;
    }

    console.log("VideoCall: Creating RTCPeerConnection");
    const pc = new RTCPeerConnection({
      iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
    });
    peerConnectionRef.current = pc;

    // Set up PC event handlers
    pc.onicecandidate = handleICECandidateEvent;
    pc.ontrack = handleRemoteTrack;
    pc.onconnectionstatechange = handleConnectionStateChange;
    pc.oniceconnectionstatechange = handleICEConnectionStateChange;
    pc.onsignalingstatechange = () => {
      console.log("VideoCall: Signaling state =>", pc.signalingState);
    };
    pc.onnegotiationneeded = onNegotiationNeeded;

    // If we are the initiator, start local media now
    if (isInitiator) {
      console.log("VideoCall: We are initiator => start local media immediately");
      startLocalMedia();
    }

    return () => {
      console.log("VideoCall: unmount => no forced cleanup here");
      // Real cleanup is in endCallAndCleanup
    };
  }, [
    isInitiator,
    handleICECandidateEvent,
    handleRemoteTrack,
    handleConnectionStateChange,
    handleICEConnectionStateChange,
    onNegotiationNeeded,
    startLocalMedia,
  ]);

  // ----------------- Re-Attach Streams if Re-Mounted -----------------
  useEffect(() => {
    // Local re-attach
    if (callType === "video" && localStreamRef.current && localVideoRef.current) {
      localVideoRef.current.srcObject = localStreamRef.current;
      localVideoRef.current.muted = true;
      localVideoRef.current.play().catch((err) => {
        console.warn("VideoCall: localVideoRef replay error:", err);
      });
    }

    // Remote re-attach
    if (remoteStreamRef.current && remoteVideoRef.current) {
      remoteVideoRef.current.srcObject = remoteStreamRef.current;
      remoteVideoRef.current.play().catch((err) => {
        console.warn("VideoCall: remoteVideoRef replay error:", err);
      });
    }
  }, [callType]);

  // ----------------- Process Incoming Signaling Messages -----------------
  const handleSignalingMessage = useCallback(
    async (data) => {
      if (!data || data.roomName !== roomName) return;
      console.log("VideoCall: handleSignalingMessage =>", data);

      const pc = peerConnectionRef.current;
      if (!pc) {
        console.warn("VideoCall: No PC available for signaling");
        return;
      }

      switch (data.signalType) {
        case "offer": {
          // Remote is offering; we are callee
          const offerDesc = new RTCSessionDescription(data.offer);
          const offerCollision =
            pc.signalingState !== "stable" || makingOfferRef.current;

          if (offerCollision) {
            // Perfect Negotiation
            if (!isPolite) {
              console.log("VideoCall: Offer collision => impolite => ignoring");
              return;
            }
            console.log("VideoCall: Offer collision => polite => rollback local desc");
            try {
              await pc.setLocalDescription({ type: "rollback" });
            } catch (err) {
              console.warn("VideoCall: rollback error:", err);
            }
          }

          console.log("VideoCall: Setting remote offer");
          await pc.setRemoteDescription(offerDesc);

          // If we haven't started local media, do it now
          if (!isLocalMediaStarted) {
            console.log("VideoCall: We are callee => start local media");
            await startLocalMedia();
          }

          console.log("VideoCall: Creating answer");
          const answer = await pc.createAnswer();
          await pc.setLocalDescription(answer);

          console.log("VideoCall: Sending answer to remote");
          webSocketManager.sendSignalingMessage(selectedUser.user.id, "answer", {
            roomName,
            answer: pc.localDescription,
          });
          break;
        }

        case "answer": {
          // We offered; they answered
          if (makingOfferRef.current || pc.signalingState === "have-local-offer") {
            console.log("VideoCall: Setting remote answer");
            const answerDesc = new RTCSessionDescription(data.answer);
            await pc.setRemoteDescription(answerDesc);
          } else {
            console.log("VideoCall: Received answer unexpectedly => ignoring");
          }
          break;
        }

        case "ice-candidate": {
          // Single ICE candidate (or null)
          const { candidate } = data;
          if (candidate) {
            try {
              await pc.addIceCandidate(new RTCIceCandidate(candidate));
              console.log("VideoCall: Added ICE candidate =>", candidate);
            } catch (err) {
              console.warn("VideoCall: Error adding ICE candidate:", err);
            }
          } else {
            console.log("VideoCall: Received null candidate => ignoring");
          }
          break;
        }

        case "call-ended": {
          console.log("VideoCall: Peer ended call => cleanup");
          endCallAndCleanup();
          break;
        }

        default:
          console.log("VideoCall: Unknown signalType:", data.signalType);
      }
    },
    [
      roomName,
      isPolite,
      endCallAndCleanup,
      isLocalMediaStarted,
      startLocalMedia,
    ]
  );

  useEffect(() => {
    if (!signalingMessages.length) return;

    signalingMessages.forEach((msg) => {
      const msgId = msg.id || uuidv4();
      if (processedMessagesRef.current.has(msgId)) return;
      processedMessagesRef.current.add(msgId);

      handleSignalingMessage(msg);
    });

    // Clear them from Redux after processing
    dispatch(clearSignalingMessages({ roomName }));
  }, [signalingMessages, handleSignalingMessage, roomName, dispatch]);

  // ----------------- Hang Up -----------------
  const handleHangUp = useCallback(() => {
    console.log("VideoCall: User clicked Hang Up");
    const theirId = selectedUser?.user?.id;
    if (theirId) {
      // Notify remote
      webSocketManager.endCall(theirId, roomName);
    }
    endCallAndCleanup();
  }, [selectedUser, roomName, endCallAndCleanup]);

  // ----------------- Toggle Mic -----------------
  const handleToggleMic = useCallback(() => {
    if (!localStreamRef.current) return;
    const audioTracks = localStreamRef.current.getAudioTracks();
    audioTracks.forEach((track) => {
      track.enabled = !track.enabled;
      console.log("VideoCall: Toggled mic:", track.id, track.enabled);
    });
    setIsMicOn((prev) => !prev);
  }, []);

  // ----------------- Toggle Camera (Enable/Disable Track) -----------------
  const handleToggleCamera = useCallback(async () => {
    if (!localStreamRef.current) return;

    const videoTracks = localStreamRef.current.getVideoTracks();
    // If there's no video track but user wants to turn camera on, 
    // we can re-obtain user media for video and add it.
    if (!videoTracks.length && !isCameraOn) {
      console.log("VideoCall: No existing video track => getUserMedia for camera ON");
      try {
        const newStream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: "user" },
        });
        const newTrack = newStream.getVideoTracks()[0];
        if (!newTrack) return;

        // Add track to local stream
        localStreamRef.current.addTrack(newTrack);

        // Attach to local preview
        if (localVideoRef.current) {
          // Simply reassign the same localStreamRef
          localVideoRef.current.srcObject = localStreamRef.current;
        }

        // Replace or add in PeerConnection
        const pc = peerConnectionRef.current;
        if (!pc) return;
        const sender = pc
          .getSenders()
          .find((s) => s.track?.kind === "video");
        if (sender) {
          await sender.replaceTrack(newTrack);
        } else {
          pc.addTrack(newTrack, localStreamRef.current);
        }

        setIsCameraOn(true);
      } catch (err) {
        console.error("VideoCall: Error enabling camera:", err);
      }
    } else {
      // We already have a track; toggle track.enabled
      videoTracks.forEach((track) => {
        track.enabled = !track.enabled;
        console.log("VideoCall: Toggled camera track:", track.id, track.enabled);
      });
      setIsCameraOn(!isCameraOn);
    }
  }, [isCameraOn]);

  // ----------------- Render -----------------
  return (
    <VideoCallContainer>
      {selectedUser?.user?.first_name && (
        <RemoteUserName>
          {selectedUser.user.first_name} {selectedUser.user.last_name}
        </RemoteUserName>
      )}

      {/* Remote video */}
      <RemoteVideo ref={remoteVideoRef} autoPlay playsInline />

      {/* Local preview if we have video in the call */}
      {isLocalMediaStarted && callType === "video" && (
        <LocalVideoPreview ref={localVideoRef} autoPlay playsInline muted />
      )}

      <ControlsBar>
        <CircleButton
          onClick={handleToggleMic}
          title={isMicOn ? "Mute" : "Unmute"}
        >
          {isMicOn ? <FiMic /> : <FiMicOff />}
        </CircleButton>

        {callType === "video" && (
          <CircleButton
            onClick={handleToggleCamera}
            title={isCameraOn ? "Turn camera off" : "Turn camera on"}
          >
            {isCameraOn ? <FiCamera /> : <FiCameraOff />}
          </CircleButton>
        )}

        <CircleButton
          onClick={handleHangUp}
          style={{ backgroundColor: "red" }}
          title="Hang Up"
        >
          <FiPhoneOff />
        </CircleButton>
      </ControlsBar>
    </VideoCallContainer>
  );
};

/**
 * Decide whether to skip re-render.
 * Only re-render if essential call props changed.
 */
function arePropsEqual(prevProps, nextProps) {
  if (
    prevProps.selectedUser?.user?.id !== nextProps.selectedUser?.user?.id ||
    prevProps.currentUser?.user?.id !== nextProps.currentUser?.user?.id ||
    prevProps.roomName !== nextProps.roomName ||
    prevProps.callType !== nextProps.callType ||
    prevProps.isInitiator !== nextProps.isInitiator
  ) {
    console.log("VideoCall: Props changed => re-render");
    return false;
  }
  return true;
}

export default React.memo(VideoCall, arePropsEqual);
