// src/pages/DoctorChatApp.js

import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import PatientProfile from '../Components/PatientProfile';
import ChatWindow from '../Components/ChatWindow';
import { fetchPatientDetails, clearPatientDetails } from '../slices/patientSlice';
import { setCurrentRoom, clearCurrentRoom } from '../slices/notificationSlice';
import { FiArrowLeft } from 'react-icons/fi'; // For mobile back button
import { FaHeartbeat } from 'react-icons/fa'; // For toggle buttons
import { generateRoomName } from '../utils'; // Centralized room naming utility
import webSocketManager from '../services/WebSocketManager';
import AddDiagnosisModal from '../Components/AddDiagnosisModal';
import MobileHeader from '../Components/MobileHeader';
import DoctorSidebar from '../Components/DoctorSidebar';

const ChatAppContainer = styled.div`
  display: flex;
  /* Ensure it can fill the parent's height */
  height: 100%;
  min-height: 0; /* important to allow flex children to shrink/scroll properly */
  overflow: hidden;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  /* Adjust cursor during resize */
  .gutter {
    background-color: #ddd;
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .gutter.gutter-horizontal {
    cursor: ew-resize;
    width: 5px;
  }
`;

const PatientProfileContainer = styled.div`
  width: ${({ width }) => width}px;
  min-width: 300px;
  max-width: 1000px;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  position: relative;
  transition: width 0.2s ease;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ddd;
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;

const Resizer = styled.div`
  width: 5px;
  cursor: ew-resize;
  background-color: #ddd;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ChatWindowContainer = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  /* ensures it can scroll/fill space properly */
  overflow: hidden;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 10px;
`;

const ToggleButtonContainerStyled = styled.div`
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const ToggleButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  font-size: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }
`;

const StartChatButton = styled.button`
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  z-index: 1000; /* Ensures it appears above other elements */

  @media (max-width: 768px) {
    display: block;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const ContentPlaceholder = styled.div`
  padding: 20px;
  text-align: center;
  color: #666;
`;

const DoctorChatApp = () => {
  const location = useLocation();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Sidebar toggle
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Mobile detection
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Chat Started state
  // Show chat by default on desktop; hidden by default on mobile
  const [isChatStarted, setIsChatStarted] = useState(!isMobile);

  // Sizing for PatientProfile
  const [profileWidth, setProfileWidth] = useState(500);
  const resizerRef = useRef(null);
  const isResizing = useRef(false);
  const lastDownX = useRef(0);

  // Modal for "Add Diagnosis"
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Redux selectors
  const doctorData = useSelector((state) => state.doctor.doctorData);
  const { details: patient, loading, error } = useSelector((state) => state.patient);

  // Flatten the doctor data
  const currentUser = useMemo(() => {
    if (doctorData && doctorData.doctor) {
      return {
        ...doctorData.doctor,
        user_type: 'doctor',
        access_token: doctorData.access_token,
        refresh_token: doctorData.refresh_token,
      };
    }
    return null;
  }, [doctorData]);

  // Ensure the patient has a user_type
  const patientWithUserType = useMemo(() => {
    if (patient) {
      return { ...patient, user_type: 'patient' };
    }
    return null;
  }, [patient]);

  // Generate a roomName
  const roomName = useMemo(() => {
    if (patientWithUserType && currentUser && currentUser.user) {
      return generateRoomName(currentUser.user.id, patientWithUserType.user.id);
    }
    return null;
  }, [currentUser, patientWithUserType]);

  // Potentially from location.state if passing in a "selectedUser"
  const [selectedUser, setSelectedUser] = useState(() => {
    return location.state?.selectedUser || null;
  });

  // If selectedUser is provided, set up chat
  useEffect(() => {
    if (selectedUser && currentUser && currentUser.user) {
      const room = generateRoomName(currentUser.user.id, selectedUser.user.id);
      dispatch(setCurrentRoom(room));
      setIsChatStarted(true);
    } else if (patientId) {
      dispatch(fetchPatientDetails(patientId));
    }

    return () => {
      dispatch(clearPatientDetails());
    };
  }, [selectedUser, patientId, dispatch, currentUser]);

  // Once we have a patient, set the current room
  useEffect(() => {
    if (patientWithUserType && roomName) {
      dispatch(setCurrentRoom(roomName));
      return () => {
        dispatch(clearCurrentRoom());
      };
    }
  }, [patientWithUserType, roomName, dispatch]);

  // Listen for window resize, update `isMobile` but do NOT forcibly close the chat
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial call
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // If we switch from mobile -> desktop, ensure chat is open
  useEffect(() => {
    if (!isMobile) {
      setIsChatStarted(true);
    }
  }, [isMobile]);

  // Resizer event handlers
  const handleMouseMove = useCallback(
    (e) => {
      if (!isResizing.current) return;
      const dx = e.clientX - lastDownX.current;
      const newWidth = profileWidth + dx;

      // clamp widths for profile
      if (newWidth > 200 && newWidth < 600) {
        setProfileWidth(newWidth);
        lastDownX.current = e.clientX;
      }
    },
    [profileWidth]
  );

  const handleMouseUp = useCallback(() => {
    if (isResizing.current) {
      isResizing.current = false;
      document.body.style.cursor = 'default';
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  const onMouseDown = useCallback((e) => {
    isResizing.current = true;
    lastDownX.current = e.clientX;
    document.body.style.cursor = 'ew-resize';
  }, []);

  // Chat state handlers
  const handleStartChat = useCallback(() => {
    setIsChatStarted(true);
  }, []);

  const handleEndChat = useCallback(() => {
    setIsChatStarted(false);
  }, []);

  // AddDiagnosisModal handlers
  const openDiagnosisModal = () => setIsModalOpen(true);
  const closeDiagnosisModal = () => setIsModalOpen(false);

  // Check app states
  const isAuthenticated = doctorData && currentUser;
  const isLoadingPatient = loading;
  const hasError = error;
  const hasPatient = patient && patient.user;

  // Latest appointment with the current doctor
  const latestAppointment = useMemo(() => {
    if (
      patientWithUserType &&
      patientWithUserType.appointments &&
      currentUser &&
      currentUser.user
    ) {
      const doctorAppointments = patientWithUserType.appointments.filter(
        (appointment) =>
          appointment.doctor &&
          appointment.doctor.user &&
          appointment.doctor.user.id === currentUser.user.id
      );
      if (doctorAppointments.length === 0) return null;

      const sortedAppointments = doctorAppointments.sort(
        (a, b) => new Date(b.appointment_time) - new Date(a.appointment_time)
      );
      return sortedAppointments[0];
    }
    return null;
  }, [patientWithUserType, currentUser]);

  return (
    <div className="doctor-dashboard" style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <div className="doctor-dashboard-overlay" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        {/*
          Show MobileHeader conditionally. 
          Example: only show if not on mobile or if chat not started.
          Adjust if you want a different behavior.
        */}
        {(!isMobile || !isChatStarted) && (
          <MobileHeader toggleSidebar={toggleSidebar} />
        )}

        <div className="dashboard-box" style={{ flex: 1, display: 'flex' }}>
          <DoctorSidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
          <div
            className={`sidebar-overlay ${isSidebarOpen ? 'active' : ''}`}
            onClick={() => setIsSidebarOpen(false)}
          ></div>

          <ChatAppContainer>
            {!isAuthenticated || isLoadingPatient || hasError || !hasPatient ? (
              <ContentPlaceholder>
                {!isAuthenticated
                  ? 'Please log in to view your chats.'
                  : isLoadingPatient
                  ? 'Loading patient details...'
                  : hasError
                  ? `Error loading patient details: ${hasError.message}`
                  : 'Loading data...'}
              </ContentPlaceholder>
            ) : (
              <>
                {/* Desktop layout */}
                {!isMobile ? (
                  <>
                    <PatientProfileContainer width={profileWidth}>
                      <PatientProfile
                        selectedUser={patientWithUserType}
                        onStartChat={handleStartChat}
                        appointment={latestAppointment}
                        openAddDiagnosisModal={openDiagnosisModal}
                      />
                      <Resizer onMouseDown={onMouseDown} />
                    </PatientProfileContainer>

                    <ChatWindowContainer>
                      <ChatWindow
                        selectedUser={patientWithUserType}
                        roomName={roomName}
                        currentUser={currentUser}
                        onBack={handleEndChat}
                      />
                    </ChatWindowContainer>
                  </>
                ) : (
                  /* Mobile Layout */
                  <>
                    {/* Patient Profile */}
                    {!isChatStarted && (
                      <PatientProfileContainer show={!isChatStarted}>
                        <PatientProfile
                          selectedUser={patientWithUserType}
                          onStartChat={handleStartChat}
                          appointment={latestAppointment}
                          openAddDiagnosisModal={openDiagnosisModal}
                        />
                      </PatientProfileContainer>
                    )}

                    {/* Chat Window */}
                    {isChatStarted && (
                      <ChatWindowContainer>
                        <ChatWindow
                          selectedUser={patientWithUserType}
                          roomName={roomName}
                          currentUser={currentUser}
                          onBack={handleEndChat}
                        />
                      </ChatWindowContainer>
                    )}

                    {/* Start Chat Button for Mobile (shows if chat not started) */}
                    {!isChatStarted && isMobile && (
                      <StartChatButton onClick={handleStartChat}>
                        Start Chat
                      </StartChatButton>
                    )}
                  </>
                )}
              </>
            )}
          </ChatAppContainer>
        </div>
      </div>

      {/* Add Diagnosis Modal */}
      {isModalOpen && (
        <AddDiagnosisModal
          isOpen={isModalOpen}
          onClose={closeDiagnosisModal}
          // Pass any needed props
        />
      )}
    </div>
  );
};

export default DoctorChatApp;
